<template>
  <div>
    <backButton />

    <template v-if="selectedItem">
      <div
        v-html="$t('Delete page item', { label: selectedItem.DisplayLabel })"
      />
      <br />

      <cancelConfirmButtons
        @goBack="goBack"
        :confirmFunction="deletePageItem"
        :loading="deleting"
        :confirmLabel="$t('Delete')"
      />
    </template>
    <template v-else-if="!$store.getters.error">
      <preloader></preloader>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: null,
      deleting: false,
      page: null,
    };
  },
  created() {
    this.getContent();
    this.$request.get("/page/" + this.$route.params.id, null, (res) => {
      if (res.success) {
        this.page = res.data;
      } else {
        const toaster = {
          message: res.message,
          timeout: false,
          type: "ERROR",
          toastClassName: ["toaster-color-error", "toaster-layout"],
          bodyClassName: ["toaster-text-layout"],
          icon: "mdi-alert-octagon",
        };
        this.$store.dispatch("setToaster", toaster);
      }
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    deletePageItem() {
      this.deleting = true;

      if (
        this.page.SynchronizationDeleteUrl &&
        this.page.SynchronizationDeleteUrl.length > 0
      ) {
        this.$request.delete(
          this.page.SynchronizationDeleteUrl + "/" + this.$route.params.itemId,
          null,
          (res) => {
            if (!res.success) {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
            this.$request.delete(
              "/content/" + this.$route.params.itemId,
              null,
              (res) => {
                this.deleting = false;

                if (res.success) {
                  this.$router.go(-1);
                } else {
                  const toaster = {
                    message: res.message,
                    timeout: false,
                    type: "ERROR",
                    toastClassName: ["toaster-color-error", "toaster-layout"],
                    bodyClassName: ["toaster-text-layout"],
                    icon: "mdi-alert-octagon",
                  };
                  this.$store.dispatch("setToaster", toaster);
                }
              }
            );
          }
        );
      } else {
        this.$request.delete(
          "/content/" + this.$route.params.itemId,
          null,
          (res) => {
            this.deleting = false;

            if (res.success) {
              this.$router.go(-1);
            } else {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
          }
        );
      }
    },
    getContent() {
      this.$request.get(
        "/content/" + this.$route.params.itemId,
        null,
        (res) => {
          if (res.success) {
            this.selectedItem = res.data;
            this.$store.dispatch(
              "setSelectedWebsiteById",
              this.selectedItem.WebsiteId
            );
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
  },
};
</script>

<style>
</style>
